<template>
    <div class="component-container login-container full-100 flex_col-ac-jsb">
        <div class="back-container">
            <router-link class="back-link"
                         :to="{ name: 'login' }">
                {{ $t('Back to login') }}
            </router-link>
        </div>
        <div class="login-container-wrapper flex_col-ac-jc">
            <div class="login-container__title">
                <h1>{{ $t('Forgot password') }}</h1>
            </div>
            <div class="login-container__inner flex_col-ac-jc">
                <h5>{{ $t(`Enter your email and we'll send you a password reset link.`) }}</h5>
                <div class="login-container__content flex_col-ac-jc">
                    <form method="POST" :action="'api/login'"
                          class="login-form flex_col-ac-jc">
                        <div class="form-group row login-form-group">
                            <div class="form-field flex_row-ac-js"
                                 :class="{ 'is-invalid' : $store.state.messages.errors.email }">
                                <label for="email" class="label-icon"></label>
                                <input id="email" type="email"
                                       class="form-control flex_row-ac-js"
                                       :placeholder="$t('Email')"
                                       v-model="email"
                                       name="email"
                                       required
                                       autocomplete="email" autofocus>
                            </div>
                        </div>
                        <div class="form-group row login-form-group">
                            <div class="form-submit flex_col-ac-jc">
                                <button type="submit" class="btn btn-primary flex_col-ac-jc"
                                        @click.prevent="submitForm">
                                    {{ $t('Send') }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'VueForgotPassword',
    data() {
      return {
        email: '',
      }
    },
    computed: {
      ...mapGetters('authentication', ['getIsUserLoggedIn']),
    },
    methods: {
      ...mapActions('authentication', ['handleForgotPassword']),
      ...mapActions('messages', ['createErrorMessage']),
      submitForm() {
        if (this.email.length > 0) {
          this.handleForgotPassword(this.email)
            .then(() => {
                this.$router.push({ name: 'login' });
              }
            ).catch(error => {
            throw new Error(error);
          });
        }
      },
    },
    mounted() {
      if (this.getIsUserLoggedIn) {
        this.$router.push({ name: 'dashboard' }).then(() => {
          this.createErrorMessage('User is already logged in!')
        });
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
