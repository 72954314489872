<template>
    <div class="component-container login-container full-100 flex_col-ac-jc">
        <div class="login-container__title">
            <h1>{{ $t('Login') }}</h1>
        </div>
        <div class="login-container__inner flex_col-ac-jc">
            <div class="login-container__content flex_col-ac-jc">
                <form method="POST" :action="'api/login'"
                      class="login-form flex_col-ac-jc">
                    <div class="form-group row login-form-group">
                        <div class="form-field flex_row-ac-js"
                             :class="{ 'is-invalid' : $store.state.messages.errors.email }">
                            <label for="email" class="label-icon"></label>
                            <input id="email" type="email"
                                   class="form-control flex_row-ac-js"
                                   :placeholder="$t('Email')"
                                   v-model="user.email"
                                   name="email"
                                   required
                                   autocomplete="email" autofocus>
                        </div>
                    </div>
                    <div class="form-group row login-form-group">
                        <div class="form-field flex_row-ac-js">
                            <label for="password" class="label-icon"></label>
                            <input id="password" type="password"
                                   class="form-control flex_row-ac-js"
                                   :class="{ 'is-invalid' : $store.state.messages.errors.password }"
                                   :placeholder="$t('Password')"
                                   v-model="user.password"
                                   name="password" required
                                   autocomplete="current-password">
                        </div>
                    </div>
                    <div class="form-group row login-form-group">
                        <div class="form-submit flex_col-ac-jc">
                            <button type="submit" class="btn btn-primary flex_col-ac-jc"
                                    @click.prevent="logMeIn">
                                {{ $t('Login') }}
                            </button>
                        </div>
                    </div>
                </form>
                <div class="forgot-password-container flex_row-ac-je">
                    <router-link :to="{name: 'forgot-password'}">
                        <span>{{ $t('Forgot password?') }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'VueLogin',
    data() {
      return {
        user: {},
      }
    },
    computed: {
      ...mapGetters('authentication', ['getIsUserLoggedIn']),
    },
    methods: {
      ...mapActions('authentication', ['handleLogin', 'handleTokenLogin']),
      ...mapActions('messages', ['createErrorMessage']),
      logMeIn() {
        if (Object.keys(this.user).length > 1) {
          this.handleLogin(this.user)
            .then(() => {
              this.$router.push({ name: 'account' })
                .catch((err) => {
                  throw new Error(`Problem while redirecting: ${ err }.`);
                });
            }).catch((err) => {
            throw new Error(`Problem while redirecting: ${ err }.`);
          });
        }
      },
    },
    created() {
      if (this.getIsUserLoggedIn) {
        this.$router.push({ name: 'account' }).then(() => {
          this.createErrorMessage('User is already logged in!')
        }).catch((err) => {
          throw new Error(`Problem while redirecting: ${ err }.`);
        });
      }
      if (this.$route.query.token) {
        let mailedToken = this.$route.query.token;
        this.handleTokenLogin(mailedToken).then(() => {
          this.$router.push({ name: 'account' }).catch((err) => {
            throw new Error(`Problem while redirecting: ${ err }.`);
          });
        });
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
