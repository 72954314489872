import Vue from "vue";
import VueRouter from "vue-router";
import VueLogin from "./components/VueLogin";
import VueForgotPassword from "./components/VueForgotPassword";
import VueDashboard from "./components/VueDashboard";
import VueContacts from "./components/VueContacts";
import VueContactProfile from "./components/VueContactProfile";
import VueTopics from "./components/VueTopics"
import VueAccount from "./components/VueAccount"
import VueTopicProfile from "./components/VueTopicProfile";
import VuePrivacyPolicy from "./components/VuePrivacyPolicy";
import Vue404 from './components/Vue404';
import store from "./store/store";

Vue.use(VueRouter);

const routes = [
  {
    name: 'notFound',
    path: '*',
    component: Vue404,
    meta: {
      title: '404 Not Found'
    }
  },
  {
    name: 'home',
    path: '/',
    redirect: {
      name: 'account'
    },
    meta: {
      title: 'Home',
      doesNotRequireAuth: false
    }
  },
  {
    name: 'login',
    path: '/login',
    component: VueLogin,
    meta: {
      title: 'Login',
      noAuthRequired: true,
    }
  },
  {
    name: 'auth',
    path: '/auth',
    component: VueLogin,
    meta: {
      title: 'Login',
      noAuthRequired: true,
    }
  },
  {
    name: 'forgot-password',
    path: '/iforgot',
    component: VueForgotPassword,
    meta: {
      title: 'Forgot password',
      noAuthRequired: true,
    }
  },
  {
    name: 'account',
    path: '/profile',
    component: VueAccount,
    props: true,
    meta: {
      title: 'Profile',
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('account/fetchAllAccountInfo', null, { root: true })
        .then(response => {
          routeTo.params.fullUserDetails = response;
          next();
        });
    }
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: VueDashboard,
    props: true,
    meta: {
      title: 'Dashboard',
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('account/fetchAllDashboardData', null, {root: true})
        .then(response => {
          routeTo.params.dashboardData = response;
          next();
        })
    }
  },
  {
    name: 'contacts',
    path: '/contacts',
    component: VueContacts,
    props: true,
    meta: {
      title: 'Contacts',
      adminOnly: true
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('contacts/fetchAllContacts', null, { root: true })
        .then(response => {
          routeTo.params.contacts = response;
          next();
        })
    }
  },
  {
    name: 'contact-profile',
    path: '/contacts/:id',
    component: VueContactProfile,
    props: true,
    meta: {
      title: 'Contact overview',
      adminOnly: true
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('contacts/fetchAllContactInfo', routeTo.params.id, {root: true})
        .then(response => {
          routeTo.params.fullContactInfo = response;
          next();
        })
    }
  },
  {
    name: 'topics',
    path: '/topics',
    component: VueTopics,
    props: true,
    meta: {
      title: 'Topics',
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('subscriptions/fetchTopicsList', null, { root: true })
        .then(response => {
          routeTo.params.topics = response;
          next();
        });
    }
  },
  {
    name: 'topic',
    path: '/topics/:id',
    component: VueTopicProfile,
    props: true,
    meta: {
      title: 'Topic overview',
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('subscriptions/fetchTopicDetails', routeTo.params.id, {root: true})
        .then(response => {
          routeTo.params.topicDetails = response;
          next();
        })
    }
  },
  {
    name: 'privacy',
    path: '/privacy-policy',
    component: VuePrivacyPolicy,
    meta: {
      title: 'Privacy policy',
      noAuthRequired: true,
      hideCookieNoticeForRoute: true,
      hideFooter: true
    }
  }
];

export default new VueRouter({
  history: true,
  mode: 'history',
  routes
});
