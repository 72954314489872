<template>
    <div class="dashboard-container px-40 py-15 flex_col--jc">
        <div class="create-container flex_row-ac-je"
             v-if="isAllowedToCreateNew">
            <router-link class="btn btn-add-new"
                         :to="{ name: 'create-topic', params: { itemType: 'topic' } }">
                {{ $t('Create new', {itemName: 'topic'}) }}
            </router-link>
        </div>
        <ul class="items-grid flex_col-ac-js"
            v-if="topics && topics.length > 0">
            <li class="list-item flex_col-ac-js" v-for="topic in topics">
                <div class="list-item__inner flex_col--js">
                    <router-link class="flex_row-ac-js"
                                 :to="{name: 'topic', params: { id: topic.id }}">
                        <strong class="flex_col-ac-jc">
                            {{ topic.id }}
                        </strong>
                        <span>{{ topic.description }}</span>
                    </router-link>
                </div>
            </li>
        </ul>
        <p class="no-items-notice"
           v-else>
            {{ $t('Currently, there are no topics.') }}
        </p>
    </div>
</template>

<script>
  export default {
    name: "VueTopics",
    props: {
      topics: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        isAllowedToCreateNew: false,
      }
    },
  }
</script>
<style scoped lang="scss">
</style>

