<template>
    <div class="dashboard-container privacy-container flex_col--jc">
        <a class="back-link flex_col-ac-js"
           @click="$router.go(-1)">
            {{ $t('Back to previous page') }}
        </a>
        <div class="profile-overview-container px-40">
            <div v-html="privacyContent"></div>
            <div class="remove-accepted-cookies flex_col-ac-js">
                <button type="button"
                        @click="removeCookieConsent"
                        :disabled="!this.getUserAcceptsCookies()">
                    {{ $t('Remove my cookies consent') }}
                </button>
                <div class="not-accepted"
                     v-if="!this.getUserAcceptsCookies()">
                    <h4>
                        {{ $t('You have not accepted our Privacy Policy yet.') }}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "VuePrivacyPolicy",
    data() {
      return {
        privacyContent: null
      }
    },
    methods: {
      ...mapActions('cookies', ['fetchPrivacyContent', 'removeCookieConsent']),
      ...mapGetters('cookies', ['getUserAcceptsCookies'])
    },
    mounted() {
      this.fetchPrivacyContent().then((response) => {
        this.privacyContent = response.content
      })
    }
  }
</script>

<style scoped lang="scss">

</style>
