import NProgress from 'nprogress';

export const namespaced = true;

const body = document.getElementsByTagName('body')[0];

export const state = {
  isLoading: false,
  menu: {
    isOpened: false
  },
  window: {
    width: 0
  },
};

export const getters = {
  getIsAppLoading: state => {
    return state.isLoading;
  },
  getIsMenuOpened: (state) => {
    return state.menu.isOpened;
  },
  getWindowSize: state => {
    return state.window.width;
  }
};

export const actions = {
  // side menu
  closeSideMenu: ({ commit }) => {
    commit('MENU_CLOSE');
  },
  openSideMenu: ({ commit }) => {
    commit('MENU_OPEN');
  },

  // window events
  handleWindowResize({ commit }) {
    commit('APP_RESIZED');
  },

  // loading
  startLoading({ commit }) {
    commit('APP_LOADING_START')
  },
  stopLoading({ commit }) {
    commit('APP_LOADING_STOP')
  },


  // updates with nprogress
  turnOnLoading({ commit }) {
    NProgress.start();
    commit('APP_LOADING_START');
    body.classList.add('modal-open');
  },
  turnOffLoading({ commit }) {
    NProgress.done();
    commit('APP_LOADING_STOP');
    body.classList.contains('modal-open') ? body.classList.remove('modal-open') : null;
  },

};

export const mutations = {
  APP_LOADING_START: (state) => {
    state.isLoading = true;
  },
  APP_LOADING_STOP: (state) => {
    state.isLoading = false;
  },
  APP_RESIZED: (state) => {
    state.window.width = window.innerWidth;
  },
  MENU_CLOSE: (state) => {
    state.menu.isOpened = false;
  },
  MENU_OPEN: (state) => {
    state.menu.isOpened = true;
  },
};
