<template>
    <div class="dashboard-container px-40 py-20 flex_col--jc"
         v-if="this.getIsUserLoggedIn()">
        <div class="dashboard-body-header flex_row-ac-jc">
            <div class="dashboard-greet-container flex_col-ac-jc">
                <h2 class="py-10">
                    {{ $t('Welcome', {name: userDetails.name}) }}
                </h2>
            </div>
        </div>
        <div class="dashboard-summary-container flex_col-ac-jc">
            <ul class="items-grid flex_rwrap-ac-jc">
                <li v-if="this.getIsUserAdmin()" class="item-contacts">
                    <router-link class="item-inner-container"
                                 :to="{ name: 'contacts' }">
                        <div class="item-image">
                            <img src="/images/icons/contacts.svg" alt="">
                        </div>
                        <div class="item-header">
                            {{ $t('Total contacts') }}
                        </div>
                        <div class="item-content">
                            <h1>{{ userContactsList.length }}</h1>
                        </div>
                    </router-link>
                </li>
                <li v-if="companyListIsNowNeeded" class="item-companies">
                    <router-link class="item-inner-container"
                                 :to="{name: 'companies'}">
                        <div class="item-image">
                            <img src="/images/icons/companies.svg" alt="">
                        </div>
                        <div class="item-header">
                            {{ $t('Total companies') }}
                        </div>
                        <div class="item-content">
                            <h1>{{ allCompaniesList.length }}</h1>
                        </div>
                    </router-link>
                </li>
                <li class="item-topics">
                    <router-link class="item-inner-container"
                                 :to="{name: 'topics' }">
                        <div class="item-image">
                            <img src="/images/icons/topics.svg" alt="">
                        </div>
                        <div class="item-header">
                            {{ $t('Total topics') }}
                        </div>
                        <div class="item-content">
                            <h1>{{ allTopicsList.length }}</h1>
                        </div>
                    </router-link>
                </li>
                <li class="item-subscriptions">
                    <router-link class="item-inner-container"
                                 :to="{ name: 'account' }">
                        <div class="item-image">
                            <img src="/images/icons/topics-subscribed.svg" alt="">
                        </div>
                        <div class="item-header">
                            {{ $t('Subscribed topics') }}
                        </div>
                        <div class="item-content">
                            <h1>{{ userSubscriptionsList.length }}</h1>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "VueDashboard",
    props: {
      dashboardData: {}
    },
    data() {
      return {
        userDetails: {},
        userContactsList: {},
        userSubscriptionsList: {},
        allTopicsList: {},
        companyListIsNowNeeded: false
      }
    },
    methods: {
      ...mapGetters('authentication', ['getIsUserLoggedIn', 'getIsUserAdmin']),
      ...mapActions('account', ['fetchAccountDetails', 'fetchMyTopicsList']),
      ...mapActions('subscriptions', ['fetchTopicsList' ]),
      ...mapActions('contacts', ['fetchAllContacts' ]),
    },
    mounted() {
      this.userDetails = this.dashboardData.userDetails;
      this.userContactsList = this.dashboardData.userContactsList;
      this.userSubscriptionsList = this.dashboardData.userSubscriptionsList;
      this.allTopicsList = this.dashboardData.allTopicsList;
    }

  }
</script>
<style scoped lang="scss">
    .dashboard-container {
        position: relative;
        width: calc(100% - 80px);
        height: calc(100% - 40px);
        -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        -moz-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        -ms-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        -o-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('/images/dsm_medarbejder_banner.jpg') no-repeat;
            background-size: cover;
            opacity: 0.1;
        }

        .dashboard-body-header {
            z-index: 1;

            h2 {
                font-weight: bold;
                letter-spacing: 1px;
                text-align: center;

            }
        }
    }
</style>

