/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import axios from 'axios';
import store from './store/store';
import router from './router.js';
import i18n from './i18n/i18n';
import NProgress from 'nprogress'

window.Vue = require('vue');
window.Vuex = require('vuex');
window.VueRouter = require('vue-router');
window.axios = require('axios');

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

const token = localStorage.getItem('userToken');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${ token }`
}

/**
 * Set current locale of the application (must be done before application initialization)
 */

i18n.locale = store.state.locale.selectedLocale;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('VueHeader', require('./components/VueHeader.vue').default);
Vue.component('VueFooter', require('./components/VueFooter.vue').default);
Vue.component('VueMenu', require('./components/VueMenu.vue').default);
Vue.component('VueLogin', require('./components/VueLogin.vue').default);
Vue.component('VueMessages', require('./components/VueMessages.vue').default);
Vue.component('VueLoading', require('./components/VueLoading.vue').default);
Vue.component('CookieNotice', require('./components/CookieNotice.vue').default);

router.beforeEach((to, from, next) => {
  store.dispatch('appEvents/turnOnLoading', null, {root: true});

  const body = $('body');

  // if mobile device, close the opened side menu
  if (store.getters['appEvents/getWindowSize'] <= 960) {
    store.dispatch('appEvents/closeSideMenu', null, {root: true});
  }

  // before entering route, check if the route should show cookie notice
  if (!!to.matched.some(record => record.meta.hideCookieNoticeForRoute)) {
    store.dispatch('cookies/showCookieNotice', false, {root: true});
    body.hasClass('modal-open') ? body.removeClass('modal-open') : false;
  } else {
    // before entering route, open cookie notice modal
    store.dispatch('cookies/showCookieNotice', true, {root: true});

    (!!store.getters['cookies/getUserAcceptsCookies']) ? body.removeClass('modal-open') : body.addClass('modal-open')
  }

  if (!!to.matched.some(record => record.meta.noAuthRequired)) {
    // route does not require authentication
    next();
  } else {
    // route requires auth
    if (store.getters['authentication/getIsUserLoggedIn']) {
      // user is logged in
      if (!!to.matched.some(record => record.meta.adminOnly)) {
        // route is for admin only
        if (store.getters['authentication/getIsUserAdmin']) {
          // user is admin
          next(); // could be other landing page for admins
        } else {
          // user is not admin
          next({ name: 'account' });
        }
      } else {
        // route is for every user
        next();
      }
    } else {
      // user is not logged in
      next({ name: 'login' });
    }
  }
});

router.afterEach(() => {
  store.dispatch('appEvents/turnOffLoading', null, {root: true})
});

router.onError(error => {
  console.log('error from router: ', error)
  // No access to `to`, `from`, `next`
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
}).$mount('#app');
