<template>
    <div class="not-found-container px-40 py-40 flex_col-ac-jc">
        <div class="not-found-img">
            <img src="/images/notfound.png"
                 :alt="$t('Page not found')">
        </div>
        <h2>
            {{ $t('Long not found text') }}
        </h2>
    </div>
</template>

<script>
  export default {
    name: "Vue404",
    data() {
      return {}
    },
  }
</script>

<style scoped lang="scss">
    .not-found-container {
        width: calc(100% - 80px);
        height: calc(100% - 80px);
        text-align: center;

        img {
            width: 100%;
            max-width: 500px;
        }

        h2 {
            text-transform: none;
        }

    }
</style>
