import axios from 'axios';

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

export const namespaced = true;

export const actions = {
  fetchAllContactInfo: ({ dispatch }, contactId) => {
    let fullContactInfo = {};
    return new Promise((resolve) => {
      dispatch('contacts/fetchContactDetails', contactId, { root: true })
        .then((response) => {
          fullContactInfo.contactDetails = response;
          dispatch('contacts/fetchContactQuestionnaire', contactId, { root: true })
            .then((response) => {
              fullContactInfo.contactQuestionnaire = response.profiles;
              dispatch('company/fetchCompanyDetails', null, { root: true })
                .then((response) => {
                  fullContactInfo.contactCompanyDetails = response;
                  dispatch('subscriptions/fetchTopicsList', null, { root: true })
                    .then((response) => {
                      fullContactInfo.topicsList = response;
                      dispatch('contacts/fetchContactSubscriptions', contactId, { root: true })
                        .then((response) => {
                          fullContactInfo.contactSubscriptions = [];
                          response.forEach((item) => {
                            fullContactInfo.contactSubscriptions.push(item.id)
                          });
                        }).then(() => {
                        resolve(fullContactInfo);
                      })
                    })
                })
            })
        })
    });
  },
  fetchContactSubscriptions({ dispatch, rootGetters }, contactId) {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/contacts/' + contactId + '/topics', {
        headers
      }).then(response => {
        if (response.status === 200) {
          return resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error);
      })
    })
  },
  fetchContactDetails({ dispatch, rootGetters }, contactId) {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/contacts/' + contactId, {
        headers
      }).then(response => {
        if (response.status === 200) {
          return resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      });
    })
  },
  fetchContactQuestionnaire: ({ dispatch, rootGetters }, contactId) => {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/contacts/' + contactId + '/profile', {
        headers
      }).then(response => {
        if (response.status === 200) {
          return resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error);
      })
    });
  },
  fetchAllContacts({ dispatch, rootGetters }) {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/contacts/', {
        headers
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
  saveContactSubscriptions({ dispatch, rootGetters }, subscriptionData) {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      axios.post('/api/contacts/' + subscriptionData.userId + '/topics', {
        topics: subscriptionData.subscriptionTopics
      }).then(response => {
        if (response.status === 200) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
          resolve(response.data.data);
          dispatch('messages/createSuccessMessage', 'Contact\'s subscriptions have been updated', { root: true });
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
  updateContactDetails({ dispatch, rootGetters }, contactDetails) {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      axios.post('/api/contacts/' + contactDetails.id, {
        name: contactDetails.name,
        address: contactDetails.address,
        address_2: contactDetails.address_2,
        zip: contactDetails.zip,
        city: contactDetails.city,
        phone: contactDetails.phone,
        mobile: contactDetails.mobile,
        email: contactDetails.email,
        admin: contactDetails.admin,
        email_subscription: contactDetails.email_subscription,
        sms_subscription: contactDetails.sms_subscription,
        password: contactDetails.password ? contactDetails.password : null
      }).then(response => {
        if (response.status === 200) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
          resolve(response.data.data);
          dispatch('messages/createSuccessMessage', 'Contact has been updated', { root: true });
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
};
