<template>
    <div class="dashboard-container flex_col--jc">
        <router-link class="back-link flex_col-ac-js"
                     :to="{name: 'contacts'}">
            {{ $t('Back to list', { listName: $t('contacts') }) }}
        </router-link>
        <div class="profile-overview-container py-40 px-40 flex_rwrap--js">
            <div class="account-image-container flex_col-ac-jc">
                <img :src="contactDetails.image ? contactDetails.image : fetchRandomPic()"
                     :alt="$t('User image')">
            </div>
            <div class="account-summary-container flex_col-as-jc">
                <div class="account-description-container flex_col-as-jc">
                    <div class="account-description-item account-name">
                        <h4>
                            {{ contactDetails.name }}
                        </h4>
                    </div>
                    <div class="account-description-item account-contacts">
                        <ul class="flex_row-ac-js">
                            <li>
                                <p>
                                    {{ contactDetails.email }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ contactDetails.phone }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ contactDetails.city }}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="account-description-item account-company">
                        <h5 v-if="contactCompanyDetails">
                            {{ contactCompanyDetails ? contactCompanyDetails.name : null }}
                        </h5>
                    </div>
                </div>
                <div class="account-actions-container flex_row-ac-js">
                    <button class="btn btn-edit"
                            v-if="!profileEditEnabled"
                            @click="profileEditEnabled = true">
                        {{ $t('Edit details') }}
                    </button>
                    <button class="btn btn-complete"
                            v-else
                            @click="profileEditEnabled = false">
                        {{ $t('Finish editing') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="account-main-details-container px-40 mt-30">
            <div class="account-main-details-list flex_col--jc">
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container flex_row-ac-js">
                        <div class="field-label field-label-details-only user-id-label">
                            {{ $t('ID') }}
                        </div>
                        <div class="field-input field-details-only user-id-field">
                            {{ contactDetails.id }}
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container flex_col-as-jc">
                        <div class="field-label">
                            {{ $t('Name') }}
                        </div>
                        <div class="field-input">
                            <input type="text"
                                   v-if="profileEditEnabled"
                                   v-model="contactDetails.name"
                                   @focusin="cloneCurrentInputValue(contactDetails.name)"
                                   @focusout="checkIfValueChanged(contactDetails.name)">
                            <div class="input-border"
                                 v-if="profileEditEnabled"></div>
                            <div class="field-details-only" v-else>
                                {{ contactDetails.name }}
                            </div>
                        </div>
                    </div>
                    <div class="small-details-container flex_col-as-jc"
                         v-if="contactCompanyDetails">
                        <div class="field-label">
                            {{ $t('Company') }}
                        </div>
                        <div class="field-input">
                            <div class="input-company-headline field-details-only"
                                 :class="showCompanyInfo ? 'expanded' : ''"
                                 @click="showCompanyInfo = !showCompanyInfo">
                                {{ (contactCompanyDetails ? contactCompanyDetails.name : null) }}
                            </div>
                            <transition name="slide-fade">
                                <div class="expandable-container"
                                     v-if="contactCompanyDetails"
                                     v-show="showCompanyInfo">
                                    <div class="double-details-container flex_row-ac-js">
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Id') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.id || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Name') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.name || '---' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="triple-details-container flex_row-ac-js">
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('VAT') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.vat || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Email') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.email || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Phone') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.phone || '---' }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-expandable small-details-container">
                                        <div class="field-label field-label-details-only">
                                            {{ $t('Address') }}
                                        </div>
                                        <div class="field-input field-details-only">
                                            {{ contactCompanyDetails.address || '---' }}
                                        </div>
                                    </div>
                                    <div class="double-details-container flex_row-ac-js">
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Zip code') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.zip || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('City') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ contactCompanyDetails.city || '---' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="triple-details-container flex_row-ac-js">
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Email') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="contactDetails.email"
                                       @focusin="cloneCurrentInputValue(contactDetails.email)"
                                       @focusout="checkIfValueChanged(contactDetails.email)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ contactDetails.email }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Phone') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="contactDetails.phone"
                                       @focusin="cloneCurrentInputValue(contactDetails.phone)"
                                       @focusout="checkIfValueChanged(contactDetails.phone)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ contactDetails.phone }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label ">
                                {{ $t('Mobile') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="contactDetails.mobile"
                                       @focusin="cloneCurrentInputValue(contactDetails.mobile)"
                                       @focusout="checkIfValueChanged(contactDetails.mobile)">
                                <div class="input-border" v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ contactDetails.mobile }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="triple-details-container flex_row-ac-js">
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Address') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="contactDetails.address"
                                       @focusin="cloneCurrentInputValue(contactDetails.address)"
                                       @focusout="checkIfValueChanged(contactDetails.address)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ contactDetails.address }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label ">
                                {{ $t('Zip code') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="contactDetails.zip"
                                       @focusin="cloneCurrentInputValue(contactDetails.zip)"
                                       @focusout="checkIfValueChanged(contactDetails.zip)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ contactDetails.zip }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('City')}}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="contactDetails.city"
                                       @focusin="cloneCurrentInputValue(contactDetails.city)"
                                       @focusout="checkIfValueChanged(contactDetails.city)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ contactDetails.city }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container px-20 flex_row--js"
                         v-if="this.getIsUserAdmin()">
                        <div class="form-check flex_row-ac-js">
                            <label class="form-check-label flex_col-as-jc" for="is-admin">
                                {{ $t('Admin') }}
                            </label>
                            <input type="checkbox" id="is-admin"
                                   v-model="contactDetails.admin"
                                   :checked="contactDetails.admin"
                                   @change="updateContactDetails(contactDetails)">
                            <span class="checkbox-custom"></span>
                        </div>
                    </div>
                    <div class="small-details-container px-20 flex_row--js">
                        <div class="form-check flex_row-ac-js">
                            <input type="checkbox" id="email-subscription"
                                   v-model="contactDetails.email_subscription"
                                   :checked="contactDetails.email_subscription"
                                   @change="updateContactDetails(contactDetails)">
                            <span class="checkbox-custom"></span>
                            <label class="form-check-label flex_col-as-jc" for="email-subscription">
                                {{ $t('Email subscription') }}
                            </label>
                        </div>
                    </div>
                    <div class="small-details-container px-20 flex_row--js">
                        <div class="form-check flex_row-ac-js">
                            <label class="form-check-label flex_col-as-jc" for="sms-subscription">
                                {{ $t('SMS subscription') }}
                            </label>
                            <input type="checkbox" id="sms-subscription"
                                   v-model="contactDetails.sms_subscription"
                                   :checked="contactDetails.sms_subscription"
                                   @change="updateContactDetails(contactDetails)">
                            <span class="checkbox-custom"></span>
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container flex_row-ac-js">
                        <div class="field-label field-label-details-only last-updated-label">
                            {{ $t('Last updated') + ':' }}
                        </div>
                        <div class="field-input last-updated-field">
                            {{ contactDetails.updated_at }}
                        </div>
                    </div>
                </div>
                <div class="subscriptions-container px-15 py-15">
                    <h3 class="px-15 py-15">
                        {{ $t('Subscriptions') }}
                    </h3>
                    <ul class="subscriptions-topic-list px-10 flex_rwrap--js">
                        <li class="subscriptions-topic-item"
                            :class="{ 'subscribed' : checkIsSubscribed(value.id) }"
                            @click.self="updateSubscription(value.id)"
                            v-for="(value, index) in topicsList">
                            <label class="flex_col-ac-jc"
                                   @click.self="updateSubscription(value.id)">
                                <input type="checkbox" class="flex_col-ac-jc"
                                       :checked="checkIsSubscribed(value.id)"/>
                                <strong @click.self="updateSubscription(value.id)">
                                    {{ value.id}}</strong>
                                {{ value.description }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="account-main-details-item px-15 py-15"
                     v-if="contactQuestionnaire.length > 0 && this.getIsUserAdmin()">
                    <h3 class="px-15 py-15">
                        {{ $t('Profile') }}
                    </h3>
                    <div class="questionnaire-container px-15 py-15">
                        <ul class="questionnaire-list flex_rwrap--js">
                            <li class="questionnaire-item"
                                v-for="(questionnaire, index) in contactQuestionnaire">
                                <div class="questionnaire-topic">
                                    <h4>
                                        {{ questionnaire.name }}
                                    </h4>
                                </div>
                                <div class="questionnaire-questions">
                                    <ul class="questionnaire-questions-list">
                                        <li class="questionnaire-question-item flex_rwrap-as-jsb"
                                            v-for="(questionnaireQuestion, index) in questionnaire.questions">
                                            <div class="field-label">
                                                {{ questionnaireQuestion.question }}
                                            </div>
                                            <ul class="questionnaire-question-answers-list">
                                                <li class="questionnaire-question-answer-item"
                                                    v-for="(questionnaireAnswer, index) in questionnaireQuestion.responses">
                                                    <div class="questionnaire-question-answer-item-text">
                                                        {{ questionnaireAnswer }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: "VueContactProfile",
    props: {
      fullContactInfo: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        contactDetails: {},
        contactQuestionnaire: [],
        contactCompanyDetails: {},
        contactSubscriptions: [],
        topicsList: [],
        valueIsAboutToChange: null,
        changedData: {},
        profileEditEnabled: false,
        showCompanyInfo: false,
      }
    },
    methods: {
      ...mapGetters('authentication', ['getIsUserAdmin']),
      ...mapActions('contacts', ['saveContactSubscriptions', 'updateContactDetails']),
      fetchRandomPic: () => {
        return 'https://picsum.photos/387'
      },
      cloneCurrentInputValue(value) {
        this.valueIsAboutToChange = value
      },
      checkIfValueChanged(value) {
        if (this.valueIsAboutToChange !== value) {
          this.updateContactDetails(this.contactDetails);
        }
      },
      checkIsSubscribed: function (topicId) {
        for (var i = 0; i < this.contactSubscriptions.length; i++) {
          if (topicId === this.contactSubscriptions[i]) {
            return true
          }
        }
        return false
      },
      updateSubscription(topicId) {
        if (this.checkIsSubscribed(topicId)) {
          this.removeSubscriptionTopic(topicId)
        } else {
          this.addSubscriptionTopic(topicId)
        }
      },
      removeSubscriptionTopic(topicId) {
        let _this = this;
        this.contactSubscriptions.forEach((item) => {
          if (item === topicId) {
            const index = _this.contactSubscriptions.indexOf(item);
            if (index > -1) {
              _this.contactSubscriptions.splice(index, 1);
              const postData = {
                userId: _this.$route.params.id,
                subscriptionTopics: _this.contactSubscriptions
              };
              _this.saveContactSubscriptions(postData)
            }
          }
        });
      },
      addSubscriptionTopic(topicId) {
        this.contactSubscriptions.push(topicId);
        const postData = {
          userId: this.$route.params.id,
          subscriptionTopics: this.contactSubscriptions
        };
        this.saveContactSubscriptions(postData);
      }
    },
    mounted() {
      this.contactDetails = this.fullContactInfo.contactDetails;
      this.contactQuestionnaire = this.fullContactInfo.contactQuestionnaire;
      this.contactCompanyDetails = this.fullContactInfo.contactCompanyDetails;
      this.contactSubscriptions = this.fullContactInfo.contactSubscriptions;
      this.topicsList = this.fullContactInfo.topicsList;
    }
  }
</script>

<style scoped lang="scss">
</style>
