import _ from "lodash";

export const namespaced = true;

export const state = {
  messages: [],
  errors: {},
  success: {}
};
let nextId = 1;


export const getters = {
  getErrorMessages: state => {
    return _.flatten(Object.values(state.errors))
  },
  getIsMessages: (state) => {
    return state.messages.length > 0;
  },
  getIsErrors: (state) => {
    return Object.keys(state.errors).length !== 0;
  },
  getIsSuccess: (state) => {
    return Object.keys(state.success).length !== 0;
  },
  getSuccessMessages: state => {
    return _.flatten(Object.values(state.success))
  },
  getValidationErrors: state => {
    return _.flatten(Object.values(state.errors));
  },
};

export const actions = {
  createErrorMessage: ({ commit }, text) => {
    let message = {
      text,
      type: 'error'
    };
    commit('PUSH_MESSAGE', message)
  },
  createSuccessMessage: ({ commit }, text) => {
    let message = {
      text,
      type: 'success'
    };
    commit('PUSH_MESSAGE', message)
  },
  removeMessage({ commit }, messageToRemove) {
    commit('DELETE_MESSAGE', messageToRemove)
  }
};

export const mutations = {
  PUSH_MESSAGE(state, message) {
    state.messages.push({
      ...message,
      id: nextId++
    })
  },
  DELETE_MESSAGE(state, messageToRemove) {
    state.messages = state.messages.filter(
      message => message.id !== messageToRemove.id
    )
  }
};

