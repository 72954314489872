<template>
    <div class="messages-container__inner"
         :class="messageTypeClass">
        <div class="messages-message">
            <strong>
                {{ $t(message.text) }}
            </strong>
        </div>
    </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'VueSingleMessage',
    props: {
      message: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        timeout: null
      }
    },
    methods: {
      ...mapActions('messages', ['removeMessage'])
    },
    mounted() {
      this.timeout = setTimeout(() => this.removeMessage(this.message), 4000)
    },
    beforeDestroy() {
      clearTimeout(this.timeout)
    },
    computed: {
      messageTypeClass() {
        return `message-${ this.message.type }`
      }
    },
  }
</script>

<style scoped>
</style>
