import i18n from "../../i18n/i18n";

export const namespaced = true;

export const state = {
    availableLanguages: ['dk', 'en', 'de', 'se'],
    selectedLocale: 'en'
};

export const getters = {
  getSelectedLanguage: (state) => {
    return state.selectedLocale;
  },
};

export const actions = {
  changeLocale({ commit }, localeId) {
    commit('UPDATE_LOCALE', localeId)
  },
};

export const mutations = {
  UPDATE_LOCALE: (state, lang) => {
    state.locale.selectedLocale = lang;
    i18n.locale = lang;
  },
};
