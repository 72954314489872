<template>
    <div class="dashboard-container flex_col--jc">
        <router-link class="back-link flex_col-ac-js"
                     :to="{name: 'topics'}">
            {{ $t('Back to list', { listName: $t('topics') }) }}
        </router-link>
        <div class="profile-overview-container py-40 px-40 flex_rwrap--js">
            <div class="account-summary-container flex_col-as-jc">
                <div class="account-description-container flex_col-as-jc">
                    <div class="account-description-item account-name">
                        <h4>
                            {{ topicDetails.id }}
                        </h4>
                    </div>
                    <div class="account-description-item account-contacts">
                        <ul class="flex_row-ac-js">
                            <li>
                                <p>
                                    {{ topicDetails.description }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "VueTopicProfile",
    props: {
      topicDetails: {
        type: Object,
        required: true,
      }
    }
  }
</script>
<style scoped lang="scss">
</style>

