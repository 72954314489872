export const namespaced = true;

let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const state = {
  cookiesAccepted: localStorage.getItem('cookies-accepted') ? localStorage.getItem('cookies-accepted') : null,
  cookieModal: {
    isOpen: true,
    showForCurrentRoute: true
  },
};

export const getters = {
  getShowCookieNoticeForCurrRoute: (state) => {
    return state.cookieModal.showForCurrentRoute;
  },
  getUserAcceptsCookies: (state) => {
    return !!state.cookiesAccepted;
  },
};

export const actions = {
  acceptCookies: ({ commit }) => {
    commit('ACCEPT_COOKIES')
  },
  removeCookieConsent: ({ commit, dispatch }) => {
    commit('REMOVE_COOKIES');
    dispatch('messages/createSuccessMessage', 'Cookie consent was successfully removed.', { root: true });
  },
  showCookieNotice({ commit }, value) {
    commit('CHANGE_COOKIE_NOTICE_VISIBILITY', value)
  },
  fetchPrivacyContent({ dispatch, rootGetters }) {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      axios.get('/api/privacy-policy', {
        headers
      }).then(response => {
        if (response.status === 200) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
          resolve(response.data.data)
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
      })
    })
  },
};

export const mutations = {
  ACCEPT_COOKIES: (state) => {
    const body = $('body');
    body.hasClass('modal-open') ? body.removeClass('modal-open') : false;
    localStorage.setItem('cookies-accepted', true);
    state.cookiesAccepted = true;
  },
  CHANGE_COOKIE_NOTICE_VISIBILITY: (state, value) => {
    state.cookieModal.showForCurrentRoute = value;
  },
  REMOVE_COOKIES: (state) => {
    localStorage.removeItem('cookies-accepted');
    state.cookiesAccepted = false;
  },
};
