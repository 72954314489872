import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en_US.json';
import da from './da_DK.json';
import de from './de_DE.json';
import no from './nb_NO.json';
import se from './sv_SE.json';

Vue.use(VueI18n);

const messages = {
  'da': {
    ...da
  },
  'en': {
    ...en
  },
  'de': {
    ...de
  },
  'no': {
    ...no
  },
  'se': {
    ...se
  }

};

export default new VueI18n({
  messages,
})