export const namespaced = true;

let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const actions = {
  fetchAllAccountInfo: ({ dispatch }) => {
    let allUserInfo = {};
    return new Promise(resolve => {
      dispatch('fetchAccountDetails')
        .then((response) => {
          allUserInfo.userDetails = response;
          dispatch('fetchAccountQuestionnaire')
            .then((response) => {
              allUserInfo.userQuestionnaire = response.profiles;
              dispatch('company/fetchCompanyDetails', null, { root: true })
                .then((response) => {
                  allUserInfo.userCompanyDetails = response;
                  dispatch('subscriptions/fetchTopicsList', null, { root: true })
                    .then((response) => {
                      allUserInfo.topicsList = response;
                      dispatch('fetchMyTopicsList')
                        .then((response) => {
                          allUserInfo.userSubscriptionsList = [];
                          response.forEach((item) => {
                            allUserInfo.userSubscriptionsList.push(item.id)
                          });
                        }).then(() => {
                        resolve(allUserInfo);
                      })
                    })
                })
            })
        })
    });
  },
  fetchAllDashboardData: ({ dispatch }) => {
    let dashboardData = [];

    return new Promise(resolve => {
      dispatch('fetchAccountDetails')
        .then(response => {
          dashboardData.userDetails = response;
          dispatch('fetchMyTopicsList')
            .then(response => {
              dashboardData.userSubscriptionsList = response;
              dispatch('subscriptions/fetchTopicsList', null, { root: true })
                .then(response => {
                  dashboardData.allTopicsList = response;
                  dispatch('contacts/fetchAllContacts', null, { root: true })
                    .then(response => {
                      dashboardData.userContactsList = response;
                    }).then(() => {
                    resolve(dashboardData)
                  })
                })
            })
        })
    })
  },
  fetchAccountDetails: ({ dispatch, rootGetters }) => {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;

    return new Promise((resolve, reject) => {
      axios.get('/api/me/', {
        headers
      }).then(response => {
        if (response.status === 200) {
          dispatch('authentication/addUser', response.data.data, { root: true });
          return resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error);
      })
    });
  },
  fetchAccountQuestionnaire: ({ dispatch, rootGetters }) => {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/me/profile', {
        headers
      }).then(response => {
        if (response.status === 200) {
          return resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error);
      })
    });
  },
  fetchMyTopicsList({ dispatch, rootGetters }) {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;

    return new Promise((resolve, reject) => {
      axios.get('/api/me/topics', {
        headers
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data.data)
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
  saveMyTopics({ dispatch, rootGetters }, subscriptionsList) {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      axios.post('/api/me/topics', {
        topics: subscriptionsList
      }).then(response => {
        if (response.status === 200) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
          resolve(response.data.data);
          dispatch('messages/createSuccessMessage', 'Profile topics have been updated', { root: true });
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
  updateMyDetails({ dispatch, rootGetters }, myDetails) {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      axios.post('/api/me', {
        name: myDetails.name,
        address: myDetails.address,
        address_2: myDetails.address_2,
        zip: myDetails.zip,
        city: myDetails.city,
        phone: myDetails.phone,
        mobile: myDetails.mobile,
        email: myDetails.email,
        admin: myDetails.admin,
        email_subscription: myDetails.email_subscription,
        sms_subscription: myDetails.sms_subscription,
        password: myDetails.password ? myDetails.password : null
      }).then(response => {
        if (response.status === 200) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
          resolve(response.data.data);
          dispatch('messages/createSuccessMessage', 'Profile has been updated', { root: true });
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
};
