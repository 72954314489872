<template>
    <transition name="slide-fade">
        <div class="messages-container flex_col-ac-jc"
             v-if="this.getIsMessages()">
            <vue-single-message v-for="message in messages"
                                :key="message.id"
                                :message="message"/>
        </div>
    </transition>
</template>

<script>
  import VueSingleMessage from "./VueSingleMessage";
  import { mapGetters, mapState } from 'vuex'

  export default {
    name: 'VueMessages',
    components: {
      VueSingleMessage
    },
    computed: {
      ...mapState('messages', ['messages'])
    },
    methods: {
      ...mapGetters('messages', ['getIsMessages'])
    }
  }
</script>
