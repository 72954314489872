<template>
    <div class="dashboard-container flex_col--jc">
        <div class="profile-overview-container py-40 px-40 flex_rwrap--js">
            <div class="account-image-container flex_col-ac-jc">
                <img :src="userDetails.image ? userDetails.image : fetchRandomPic()"
                     :class="userDetails.image ? '': 'image-loading'"
                     :alt="$t('Company logo')">
            </div>
            <div class="account-summary-container flex_col-as-jc">
                <div class="account-description-container flex_col-as-jc">
                    <div class="account-description-item account-name">
                        <h4>
                            {{ userDetails.name }}
                        </h4>
                    </div>
                    <div class="account-description-item account-contacts">
                        <ul class="flex_row-ac-js">
                            <li>
                                <p>
                                    {{ userDetails.email }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ userDetails.phone }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ userDetails.city }}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="account-description-item account-company">
                        <h5 v-if="userCompanyDetails">
                            {{ userCompanyDetails ? userCompanyDetails.name : null }}
                        </h5>
                    </div>
                </div>
                <div class="account-actions-container flex_row-ac-js">
                    <button class="btn btn-edit"
                            v-if="!profileEditEnabled"
                            @click="profileEditEnabled = true">
                        {{ $t('Edit details') }}
                    </button>
                    <button class="btn btn-complete"
                            v-else
                            @click="profileEditEnabled = false">
                        {{ $t('Finish editing') }}
                    </button>
                    <button class="btn btn-complete"
                            v-if="isChangePasswordClicked"
                            @click="isChangePasswordClicked = !isChangePasswordClicked; checkPasswordMatch()">
                        {{ $t('Submit password') }}
                    </button>
                    <button class="btn btn-change-pass"
                            v-else
                            @click="isChangePasswordClicked = !isChangePasswordClicked">
                        {{ $t('Change password') }}
                    </button>
                </div>
                <transition name="slide-fade">
                    <div class="account-change-password-container flex_col-ac-jc" v-show="isChangePasswordClicked">
                        <div class="double-details-container flex_row-ac-js">
                            <div class="small-details-container flex_col-as-jc">
                                <div class="field-label">
                                    {{ $t('New password') }}
                                </div>
                                <div class="field-input">
                                    <input type="password" v-model="newPassword">
                                    <div class="input-border"></div>
                                </div>
                            </div>
                            <div class="small-details-container flex_col-as-jc">
                                <div class="field-label">
                                    {{ $t('Repeat new password') }}
                                </div>
                                <div class="field-input">
                                    <input type="password" v-model="confirmPassword">
                                    <div class="input-border"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="slide-fade" v-if="profileEditEnabled">
                    <p class="picture-change-notice">{{ $t('You can change profile picture on') }}
                        <a href="https://gravatar.com" target="_blank">Gravatar.com</a>
                    </p>
                </transition>
            </div>
        </div>
        <div class="account-main-details-container px-40 mt-30">
            <div class="account-main-details-list flex_col--jc">
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container flex_row-ac-js">
                        <div class="field-label user-id-label">
                            {{ $t('ID') }}
                        </div>
                        <div class="field-input user-id-field">
                            {{ userDetails.id }}
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container flex_col-as-jc">
                        <div class="field-label">
                            {{ $t('Name') }}
                        </div>
                        <div class="field-input">
                            <input type="text"
                                   v-if="profileEditEnabled"
                                   v-model="userDetails.name"
                                   @focusin="cloneCurrentInputValue(userDetails.name)"
                                   @focusout="checkIfValueChanged(userDetails.name)">
                            <div class="input-border"
                                 v-if="profileEditEnabled"></div>
                            <div class="field-details-only" v-else>
                                {{ userDetails.name }}
                            </div>
                        </div>
                    </div>
                    <div class="small-details-container flex_col-as-jc">
                        <div class="field-label">
                            {{ $t('Company') }}
                        </div>
                        <div class="field-input">
                            <div class="input-company-headline field-details-only"
                                 :class="showCompanyInfo ? 'expanded' : ''"
                                 @click="showCompanyInfo = !showCompanyInfo">
                                {{ (userCompanyDetails ? userCompanyDetails.name : null)
                                }}
                            </div>
                            <transition name="slide-fade">
                                <div class="expandable-container"
                                     v-if="userCompanyDetails"
                                     v-show="showCompanyInfo">
                                    <div class="double-details-container flex_row-ac-js">
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Id') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.id || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Name') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.name || '---' }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="triple-details-container flex_row-ac-js">
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('VAT') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.vat || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Email') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.email || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Phone') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.phone || '---' }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-expandable small-details-container">
                                        <div class="field-label field-label-details-only">
                                            {{ $t('Address') }}
                                        </div>
                                        <div class="field-input field-details-only">
                                            {{ userCompanyDetails.address || '---' }}
                                        </div>
                                    </div>
                                    <div class="double-details-container flex_row-ac-js">
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('Zip code') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.zip || '---' }}
                                            </div>
                                        </div>
                                        <div class="field-expandable small-details-container">
                                            <div class="field-label field-label-details-only">
                                                {{ $t('City') }}
                                            </div>
                                            <div class="field-input field-details-only">
                                                {{ userCompanyDetails.city || '---' }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="triple-details-container flex_row-ac-js">
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Email') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="userDetails.email"
                                       @focusin="cloneCurrentInputValue(userDetails.email)"
                                       @focusout="checkIfValueChanged(userDetails.email)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ userDetails.email }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Phone') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="userDetails.phone"
                                       @focusin="cloneCurrentInputValue(userDetails.phone)"
                                       @focusout="checkIfValueChanged(userDetails.phone)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ userDetails.phone }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Mobile') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="userDetails.mobile"
                                       @focusin="cloneCurrentInputValue(userDetails.mobile)"
                                       @focusout="checkIfValueChanged(userDetails.mobile)">
                                <div class="input-border" v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ userDetails.mobile }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="triple-details-container flex_row-ac-js">
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Address') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="userDetails.address"
                                       @focusin="cloneCurrentInputValue(userDetails.address)"
                                       @focusout="checkIfValueChanged(userDetails.address)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ userDetails.address }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('Zip code') }}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="userDetails.zip"
                                       @focusin="cloneCurrentInputValue(userDetails.zip)"
                                       @focusout="checkIfValueChanged(userDetails.zip)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ userDetails.zip }}
                                </div>
                            </div>
                        </div>
                        <div class="small-details-container flex_col-as-jc">
                            <div class="field-label">
                                {{ $t('City')}}
                            </div>
                            <div class="field-input">
                                <input type="text"
                                       v-if="profileEditEnabled"
                                       v-model="userDetails.city"
                                       @focusin="cloneCurrentInputValue(userDetails.city)"
                                       @focusout="checkIfValueChanged(userDetails.city)">
                                <div class="input-border"
                                     v-if="profileEditEnabled"></div>
                                <div class="field-details-only" v-else>
                                    {{ userDetails.city }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container px-20 flex_row--js"
                         v-if="this.getIsUserAdmin()">
                        <div class="form-check flex_row-ac-js">
                            <label class="form-check-label flex_col-as-jc" for="is-admin">
                                {{ $t('Admin') }}
                            </label>
                            <input type="checkbox" id="is-admin"
                                   disabled
                                   :checked="userDetails.admin">
                            <span class="checkbox-custom"></span>
                        </div>
                    </div>
                    <div class="small-details-container px-20 flex_row--js">
                        <div class="form-check flex_row-ac-js">
                            <input type="checkbox" id="email-subscription"
                                   v-model="userDetails.email_subscription"
                                   :checked="userDetails.email_subscription"
                                   @change="updateMyDetails(userDetails)">
                            <span class="checkbox-custom"></span>
                            <label class="form-check-label flex_col-as-jc" for="email-subscription">
                                {{ $t('Email subscription') }}
                            </label>
                        </div>
                    </div>
                    <div class="small-details-container px-20 flex_row--js">
                        <div class="form-check flex_row-ac-js">
                            <label class="form-check-label flex_col-as-jc" for="sms-subscription">
                                {{ $t('SMS subscription') }}
                            </label>
                            <input type="checkbox" id="sms-subscription"
                                   v-model="userDetails.sms_subscription"
                                   :checked="userDetails.sms_subscription"
                                   @change="updateMyDetails(userDetails)">
                            <span class="checkbox-custom"></span>
                        </div>
                    </div>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <div class="small-details-container flex_row-ac-js">
                        <div class="field-label last-updated-label">
                            {{ $t('Last updated') + ':' }}
                        </div>
                        <div class="field-input last-updated-field">
                            {{ userDetails.updated_at }}
                        </div>
                    </div>
                </div>
                <div class="subscriptions-container px-15 py-15">
                    <h3 class="px-15 py-15">
                        {{ $t('Subscriptions') }}
                    </h3>
                    <ul class="subscriptions-topic-list px-10 flex_rwrap--js">
                        <li class="subscriptions-topic-item"
                            :class="{ 'subscribed' : isSubscribed(topic.id) }"
                            @click.self="updateSubscription(topic.id)"
                            :key="index"
                            v-for="(topic, index) in topicsList">
                            <label class="flex_col-ac-jc"
                                   @click.self="updateSubscription(topic.id)">
                                <input type="checkbox" class="flex_col-ac-jc"
                                       :checked="isSubscribed(topic.id)">
                                <strong @click.self="updateSubscription(topic.id)">
                                    {{ topic.id}}
                                </strong>
                                {{ topic.description }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="account-main-details-item px-15 py-15">
                    <h3 class="px-15 py-15">
                        {{ $t('Profile') }}
                    </h3>
                    <div class="questionnaire-container px-15 py-15">
                        <ul class="questionnaire-list flex_rwrap--js">
                            <li class="questionnaire-item"
                                v-for="(questionnaire, index) in userQuestionnaire">
                                <div class="questionnaire-topic">
                                    <h4>
                                        {{ questionnaire.name }}
                                    </h4>
                                </div>
                                <div class="questionnaire-questions">
                                    <ul class="questionnaire-questions-list">
                                        <li class="questionnaire-question-item flex_rwrap-as-jsb"
                                            v-for="(questionnaireQuestion, index) in questionnaire.questions">
                                            <div class="field-label">
                                                {{ questionnaireQuestion.question }}
                                            </div>
                                            <ul class="questionnaire-question-answers-list">
                                                <li class="questionnaire-question-answer-item"
                                                    v-for="(questionnaireAnswer, index) in questionnaireQuestion.responses">
                                                    <div class="questionnaire-question-answer-item-text">
                                                        {{ questionnaireAnswer }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "VueAccount",
    props: {
      fullUserDetails: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        confirmPassword: null,
        isChangePasswordClicked: false,
        newPassword: null,
        profileEditEnabled: false,
        showCompanyInfo: false,
        topicsList: [],
        userCompanyDetails: {},
        userDetails: {},
        userSubscriptionsList: [],
        userQuestionnaire: [],
        valueIsAboutToChange: null,
      }
    },
    methods: {
      ...mapGetters('authentication', ['getIsUserAdmin']),
      ...mapActions('account', ['saveMyTopics', 'updateMyDetails']),
      ...mapActions('messages', ['createSuccessMessage', 'createErrorMessage']),
      fetchRandomPic: () => {
        return 'https://picsum.photos/387'
      },
      addSubscriptionTopic(topicId) {
        this.userSubscriptionsList.push(topicId);
        this.saveMyTopics(this.userSubscriptionsList);
      },
      checkIfValueChanged(value) {
        if (this.valueIsAboutToChange !== value) {
          this.updateMyDetails(this.userDetails);
        }
      },
      isSubscribed: function (topicId) {
        for (var i = 0; i < this.userSubscriptionsList.length; i++) {
          if (topicId === this.userSubscriptionsList[i]) {
            return true;
          }
        }
        return false;
      },
      checkPasswordMatch: function () {
        if (!this.newPassword || !this.confirmPassword) {
          this.createErrorMessage('Please enter password into both fields');
          return false;
        } else if (this.newPassword && this.confirmPassword) {
          if (this.newPassword === this.confirmPassword) {
            this.userDetails.password = this.confirmPassword;
            this.updateMyDetails(this.userDetails);
            return true;
          } else {
            this.createErrorMessage('Passwords do not match');
            return false;
          }
        }
      },
      cloneCurrentInputValue(value) {
        this.valueIsAboutToChange = value
      },
      removeSubscriptionTopic(topicId) {
        let _this = this;

        this.userSubscriptionsList.forEach((item) => {
          if (item === topicId) {
            const index = _this.userSubscriptionsList.indexOf(item);
            if (index > -1) {
              _this.userSubscriptionsList.splice(index, 1);
              _this.saveMyTopics(_this.userSubscriptionsList).then(response => {
                _this.userSubscriptionsList = [];
                response.forEach((item) => {
                  _this.userSubscriptionsList.push(item.id)
                })
              })
            }
          }
        });
      },
      updateSubscription(topicId) {
        if (this.isSubscribed(topicId)) {
          this.removeSubscriptionTopic(topicId)
        } else {
          this.addSubscriptionTopic(topicId)
        }
      },
    },
    mounted() {
      this.userDetails = this.fullUserDetails.userDetails;
      this.userQuestionnaire = this.fullUserDetails.userQuestionnaire;
      this.userCompanyDetails = this.fullUserDetails.userCompanyDetails;
      this.userSubscriptionsList = this.fullUserDetails.userSubscriptionsList;
      this.topicsList = this.fullUserDetails.topicsList;
    }

  }
</script>
<style scoped lang="scss">
</style>

