<template>
    <div class="cookie-notice-popup flex_col-ac-jc"
         v-if="this.getShowCookieNoticeForCurrRoute() && !this.getUserAcceptsCookies()">
        <div class="cookie-notice-inner">
            <h2>{{ $t('Review our Privacy Policy') }}</h2>
            <i18n path="Cookie-consent" tag="label" for="Privacy Policy">
                <router-link :to="{name: 'privacy'}">
                    {{ $t('Privacy Policy') }}
                </router-link>
            </i18n>
            <button @click="acceptCookies()">{{ $t('Accept') }}</button>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "CookieNotice",
    data() {
      return {
        modalOpen: true,
      }
    },
    methods: {
      ...mapGetters('cookies', [
        'getShowCookieNoticeForCurrRoute',
        'getUserAcceptsCookies']),
      ...mapActions('cookies', ['acceptCookies']),
    },
    mounted() {
      this.modalOpen = this.getShowCookieNoticeForCurrRoute;
    }
  }
</script>

<style scoped lang="scss">

</style>
