<template>
    <div class="menu-container"
         :class="this.getIsMenuOpened() ? 'opened' : 'closed'"
         v-if="this.getIsUserLoggedIn()">
        <div class="menu-container__inner flex_col--js">
            <div class="company-info-block py-15 flex_row-ac-jc">
                <div class="company-logo flex_col-ac-jsb">
                    <img src="/images/jmalogo_hvid.svg" alt="">
                </div>
                <div class="company-title flex_row-ac-js"
                     v-show="this.getIsMenuOpened()">
                    <h1>JMA</h1>
                </div>
            </div>
            <div class="menu-control-container"
                 :class="this.getIsMenuOpened() ? 'flex_col-ac-je' : 'flex_col-ac-jc'"
                 @click="toggleMenu()"></div>
            <div class="menu-list-container py-10 flex_col-as-js">
                <ul class="menu-item-list flex_col--js">
                    <li class="menu-item ">
                        <router-link :to="{name: 'dashboard'}"
                                     :title="$t('Dashboard')"
                                     class="menu-link flex_row-ac-js"
                                     exact>
                            <span class="menu-icon icon-dashboard"></span>
                            <div class="menu-link-text"
                                 v-show="this.getIsMenuOpened()">
                                {{ $t('Dashboard') }}
                            </div>
                        </router-link>
                    </li>
                    <li class="menu-item" v-if="this.getIsUserAdmin()">
                        <router-link :to="{name: 'contacts'}"
                                     :title="$t('Contacts')"
                                     class="menu-link flex_row-ac-js"
                                     exact>
                            <span class="menu-icon icon-contacts"></span>
                            <div class="menu-link-text"
                                 v-show="this.getIsMenuOpened()">
                                {{ $t('Contacts') }}
                            </div>
                        </router-link>
                    </li>
                    <li class="menu-item">
                        <router-link :to="{name: 'topics'}"
                                     :title="$t('Topics')"
                                     class="menu-link flex_row-ac-js"
                                     exact>
                            <span class="menu-icon icon-topics"></span>
                            <div class="menu-link-text"
                                 v-show="this.getIsMenuOpened()">
                                {{ $t('Topics') }}
                            </div>
                        </router-link>
                    </li>
                    <li class="menu-item">
                        <router-link :to="{name: 'account'}"
                                     :title="$t('Profile')"
                                     class="menu-link flex_row-ac-js"
                                     exact>
                            <span class="menu-icon icon-profile"></span>
                            <div class="menu-link-text"
                                 v-show="this.getIsMenuOpened()">
                                {{ $t('Profile') }}
                            </div>
                        </router-link>
                    </li>
                    <li class="menu-item">
                        <div class="menu-link flex_row-ac-js"
                             @click="openSideMenu()">
                            <span class="menu-icon icon-language"></span>
                            <div class="menu-link-text menu-language flex_row-ac-jsa"
                                 v-show="this.getIsMenuOpened()">
                                <span class="icon-locale icon-denmark flex_col-ac-jc"
                                      @click="changeLocale('da')"
                                      :title="$t('Change language to', {locale: $t('Danish') })"></span>
                                <span class="icon-locale icon-germany flex_col-ac-jc"
                                      @click="changeLocale('de')"
                                      :title="$t('Change language to', {locale: $t('German') })"></span>
                                <span class="icon-locale icon-norway flex_col-ac-jc"
                                      @click="changeLocale('no')"
                                      :title="$t('Change language to', {locale: $t('Norwegian') })"></span>
                                <span class="icon-locale icon-sweden flex_col-ac-jc"
                                      @click="changeLocale('se')"
                                      :title="$t('Change language to', {locale: $t('Swedish') })"></span>
                                <span class="icon-locale icon-uk flex_col-ac-jc"
                                      @click="changeLocale('en')"
                                      :title="$t('Change language to', {locale: $t('English') })"></span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link class="simple-link flex_row-ac-js"
                                     :to="{name: 'privacy'}"
                                     :title="$t('Privacy policy')"
                                     v-show="this.getIsMenuOpened()"
                                     exact>
                            <div class="menu-link-text">
                                {{ $t('Privacy policy') }}
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'SideMenu',
    data() {
      return {
        company: {
          title: null,
          logo: null
        }
      }
    },
    methods: {
      ...mapGetters('appEvents', ['getIsMenuOpened']),
      ...mapGetters('authentication', ['getIsUserLoggedIn', 'getIsUserAdmin']),
      ...mapActions('appEvents', ['openSideMenu', 'closeSideMenu']),
      ...mapActions('locale', ['changeLocale']),
      toggleMenu() {
        !!this.getIsMenuOpened() ? this.closeSideMenu() : this.openSideMenu();
      }
    },
  }
</script>

<style scoped lang="scss">
</style>
