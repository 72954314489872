import router from "../../router";
import axios from "axios";

export const namespaced = true;

const apiClient = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

export const state = {
  userName: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).name : 'no username',
  userCompany: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).company.name : 'No company',
  admin: JSON.parse(localStorage.getItem('user')) ? !!JSON.parse(localStorage.getItem('user')).admin : false,
  tokenValue: localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null,
};

export const getters = {
  getCompanyName: (state) => {
    return state.userCompany;
  },
  getIsUserAdmin: (state) => {
    return !!state.admin;
  },
  getIsUserLoggedIn: (state) => {
    return !!state.tokenValue;
  },
  getUserName: (state) => {
    return state.userName;
  },
  getUserToken: (state) => {
    return state.tokenValue;
  },
};

export const actions = {
  addUser: ({ commit }, userDetails) => {
    if (!!userDetails) {
      commit('ADD_USER_TO_STORAGE', userDetails)
    } else {
      throw new Error('User details are missing in response.')
    }
  },
  handleLogin: ({ commit, dispatch, rootGetters }, postedUserData) => {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      apiClient.post('/api/login', {
        email: postedUserData.email,
        password: postedUserData.password,
      }).then(response => {
        if (response.status === 200) {
          if (response.data.token) {
            const userToken = response.data.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${ userToken }`;
            commit('MAKE_USER_LOGGED_IN', userToken);
            dispatch('messages/createSuccessMessage', 'You are now logged in', { root: true });
            resolve();
          } else {
            dispatch('messages/createErrorMessage', 'Invalid email or password', { root: true });
          }
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true })
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server error (500).', { root: true })
        }
        commit('MAKE_USER_LOGGED_OUT');
        reject(error);
      });
    });
  },
  handleTokenLogin: ({ commit, dispatch, rootGetters }, mailedToken) => {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      apiClient.post('/api/token/', {
        token: mailedToken
      }).then(response => {
        if (response.status === 200) {
          if (response.data.token) {
            const userToken = response.data.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${ userToken }`;
            commit('MAKE_USER_LOGGED_IN', userToken);
            if (!!rootGetters['appEvents/getIsAppLoading']) {
              dispatch('appEvents/turnOffLoading', null, { root: true });
            }
            dispatch('messages/createSuccessMessage', 'You are now logged in. Remember to change your password!', { root: true });
            resolve();
          } else {
            dispatch('messages/createErrorMessage', 'Could not log you in.', { root: true });
          }
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        commit('MAKE_USER_LOGGED_OUT');
        reject(error);
      });
    });
  },
  handleForgotPassword: ({ dispatch, rootGetters }, postedEmail) => {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve, reject) => {
      apiClient.post('/api/forgotpassword/', {
        email: postedEmail
      }).then(response => {
        if (response.status === 200) {
          if (!!rootGetters['appEvents/getIsAppLoading']) {
            dispatch('appEvents/turnOffLoading', null, { root: true });
          }
          dispatch('messages/createSuccessMessage', response.data.data.message, { root: true });
          return resolve(response.data.data)
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error);
      })
    });
  },
  logoutUser: ({ commit, dispatch, rootGetters }) => {
    dispatch('appEvents/turnOnLoading', null, { root: true });
    return new Promise((resolve) => {
      commit('MAKE_USER_LOGGED_OUT');
      delete axios.defaults.headers.common['Authorization'];
      if (!!rootGetters['appEvents/getIsAppLoading']) {
        dispatch('appEvents/turnOffLoading', null, { root: true });
      }
      dispatch('messages/createSuccessMessage', 'Logged out', { root: true });
      resolve();
    });
  },
};

export const mutations = {
  MAKE_USER_LOGGED_IN: (state, userToken) => {
    localStorage.setItem('userToken', userToken);
    state.tokenValue = userToken;
  },
  MAKE_USER_LOGGED_OUT: (state) => {
    state.tokenValue = null;
    state.userName = null;
    state.admin = false;
    if (localStorage.getItem('userToken')) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');
    }
    router.push({ name: 'login' });
  },
  ADD_USER_TO_STORAGE: (state, value) => {
    localStorage.setItem('user', JSON.stringify(value));
    state.admin = value.admin
  },
};
