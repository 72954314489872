<template>
    <div class="page-loader-container_outer"
         v-if="this.getIsAppLoading()"
         :class="this.getIsAppLoading() ? 'loading' : ''">
        <div class="page-loader-container_inner">
            <div class="loader-outer"></div>
            <div class="loader-inner"></div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "VueLoading",
    methods: {
      ...mapGetters('appEvents', ['getIsAppLoading'])
    }
  }
</script>

<style scoped lang="scss">
</style>
