export const namespaced = true;

export const state = {
  currentPage: 1,
  nextPages: [],
  previousPages: [],
  totalPages: null
};

export const mutations = {
  UPDATE_PAGINATION: (state, { previousPages, currentPage, nextPages, totalPages }) => {
    state.previousPages = previousPages;
    state.currentPage = currentPage;
    state.nextPages = nextPages;
    state.totalPages = totalPages;
  }
};
