<!--suppress HtmlUnknownTarget -->
<template>
    <div class="dashboard-container px-40 py-15 flex_col--jc">
        <div class="create-container flex_row-ac-je"
             v-if="isAllowedToCreateNew">
            <router-link class="btn btn-add-new"
                         :to="{ name: 'create-contact', params: { itemType: 'contact' } }">
                {{ $t('Create new', {itemName: 'contact'}) }}
            </router-link>
        </div>
        <ul class="items-grid flex_col-ac-js"
        v-if="contacts && contacts.length > 0">
            <li class="list-item flex_col-ac-js" v-for="contact in contacts">
                <div class="list-item__inner flex_col--js">
                    <router-link class="flex_row-ac-js"
                                 :to="{ name: 'contact-profile', params: { id: contact.id } }">
                        <div class="item-image-container flex_col-ac-jc">
                            <img :src="contact.image ? contact.image : fetchRandomPic()"
                                 :alt="$t('User image')">
                        </div>
                        <strong class="flex_col-ac-jc">
                            {{ contact.name }}
                        </strong>
                        <span class="flex_col-ac-jc">{{ contact.email }}</span>
                    </router-link>
                </div>
            </li>
        </ul>
        <p class="no-items-notice"
                v-else>
            {{ $t('Currently, user does not have any contacts.') }}
        </p>
    </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {

    name: "VueContacts",
    props: {
      contacts: {
        type: Array,
        required: true
      },
    },
    data() {
      return {
        isAllowedToCreateNew: false
      }
    },
    methods: {
      fetchRandomPic: () => {
        return 'https://picsum.photos/387'
      },
    },
  }
</script>
<style scoped lang="scss">
</style>

