<template>
    <header class="px-40 py-15 flex_rwrap--jsb w-100"
    v-if="this.getIsUserLoggedIn()">
        <div class="header-inner-container header-breadcrumbs-container">
            <div class="burger-icon"
                 :class="this.getIsMenuOpened() ? 'active' : ''"
                 @click="toggleMenu()">
                <div></div>
            </div>
        </div>
        <div class="header-inner-container header-title-container flex_row-ac-jc">
            <h3 v-if="$route.meta.title">
                {{ $t($route.meta.title + ($route.params.itemType ? ' ' + $route.params.itemType : '')) }}
            </h3>
        </div>
        <div class="header-inner-container header-actions-container flex_row-ac-je">
            <ul class="profile-items flex_row-ac-jc">
                <li class="profile-item flex-row-ac-jc">
                    <div class="user-image flex_col-ac-jc">
                        <img :src="userDetails.image ? userDetails.image : fetchRandomPic()"
                             :class="userDetails.image ? '': 'image-loading'"
                             :alt="$t('User image')">
                    </div>
                </li>
                <li class="profile-item flex_row-ac-jc">
                    <div class="profile-details flex_row-ac-jsb">
                        <router-link :to="{ name: 'account'}"
                                     :title="$t('View profile')">
                            {{ userDetails.name }}
                        </router-link>
                    </div>
                </li>
                <li class="profile-dropdown-container flex_col-ac-js">
                    <ul class="profile-dropdown-items flex_col-ac-js">
                        <li class="profile-dropdown-item"
                            @click.stop="logoutUser()">
                            <div class="logout-link flex_row-ac-js">
                                {{ $t('Logout') }}
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "VueHeader",
    props: {
      username: String,
      logoutLink: String,
    },
    data() {
      return {
        userDetails: {},
        window: {
          width: 0
        }
      }
    },
    methods: {
      ...mapGetters('appEvents', ['getIsMenuOpened', 'getWindowSize']),
      ...mapGetters('authentication', ['getIsUserLoggedIn']),
      ...mapActions('account', ['fetchAccountDetails']),
      ...mapActions('appEvents', ['openSideMenu',
        'closeSideMenu',
        'handleWindowResize']),
      ...mapActions('locale', ['changeLocale']),
      ...mapActions('authentication', ['logoutUser']),
      fetchRandomPic: () => {
        return 'https://picsum.photos/387'
      },
      toggleMenu() {
        !!this.getIsMenuOpened() ? this.closeSideMenu() : this.openSideMenu();
      }
    },
    created() {
      this.fetchAccountDetails().then((response) => {
        this.userDetails = response;

        if (response.preferredLocale && response.preferredLocale.length > 0) {
          this.changeLocale(response.preferredLocale);
        }
      });

      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleWindowResize());
    },
  }
</script>

<style scoped lang="scss">
</style>
