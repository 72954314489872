export const namespaced = true;

let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const actions = {
  fetchTopicsList({ dispatch, rootGetters }) {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/topics/', {
        headers
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
        reject(error)
      })
    })
  },
  fetchTopicDetails({ dispatch, rootGetters }, topicId) {
    headers.Authorization = `Bearer ${ localStorage.getItem('userToken') }`;
    return new Promise((resolve, reject) => {
      axios.get('/api/topics/' + topicId, {
        headers
      }).then(response => {
        if (response.status === 200) {
          // dispatch('appEvents/stopLoading', null, { root: true });
          resolve(response.data.data)
        } else {
          dispatch('messages/createErrorMessage', 'Response did not return status 200', { root: true });
          reject('Reject due to error');
        }
      }).catch(error => {
        if (!!rootGetters['appEvents/getIsAppLoading']) {
          dispatch('appEvents/turnOffLoading', null, { root: true });
        }
        if (error.response.status === 401) {
          dispatch('messages/createErrorMessage', 'Unauthorized', { root: true });
          dispatch('authentication/logoutUser', null, { root: true });
        } else if (error.response.status === 422) {
          for (let key in error.response.data.errors) {
            const errorKeys = error.response.data.errors[key];
            for (let errKey in errorKeys) {
              dispatch('messages/createErrorMessage', errorKeys[errKey], { root: true });
            }
          }
        } else if (error.response.status === 429) {
          dispatch('messages/createErrorMessage', 'Slow down! Refresh the page after a minute.', { root: true });
        } else if (error.response.status === 500) {
          dispatch('messages/createErrorMessage', 'Server  error (500).', { root: true });
        }
      })
    })
  },
};
