<template>
    <div class="footer-container flex_col-ac-jc">
        <router-link :to="{ name: 'privacy' }">
            {{ $t('Privacy policy') }}
        </router-link>
    </div>
</template>

<script>
  export default {
    name: "VueFooter"
  }
</script>

<style scoped lang="scss">
</style>
